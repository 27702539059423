<ion-page>
	<ion-header>
		<ion-toolbar>
			<ion-buttons slot="start">
		        <ion-button class="btn-exit" @click="Back">
			        <ion-icon slot="icon-only" :icon="chevronBackOutline"></ion-icon>
			    </ion-button>
		    </ion-buttons>
			<ion-buttons slot="start">
				<h5 class="titulo-head">Detalle servicio</h5>
		    </ion-buttons>
		</ion-toolbar>
	</ion-header>
	<ion-content>
		<div class="contenido-home">
			<ion-row responsive-lg>
		        <ion-col>
					<ion-row class="seccion-titulo ">
						<ion-col class="dato" size="12">
							<ion-label class=" cen-vh text-color-primario">
								<b>Servicio</b>
							</ion-label>
						</ion-col>
					</ion-row>
			        <ion-card class="card-servicio">
			            <ion-card-content class="contenido">
							<ion-row class="seccion-texto ">
								<ion-col class="dato" size="6">
									<ion-label class="text-color-primario">
										<b>Solicitud de:</b>
									</ion-label>
								</ion-col>
								<ion-col class="valor" size="6">
									<ion-label>
										{{ servicio.nombre }}
									</ion-label>
								</ion-col>
							</ion-row>
							<ion-row class="seccion-texto ">
								<ion-col class="dato" size="6" >
									<ion-label class="text-color-primario">
										<b>Categoría:</b>
									</ion-label>
								</ion-col>
								<ion-col class="valor" size="6">
									<ion-label>
										{{ servicio.categoria.nombre }}
									</ion-label>
								</ion-col>
							</ion-row >
							<ion-row class="seccion-texto ">
								<ion-col class="dato" size="6">
									<ion-label class="text-color-primario">
										<b>Programado:</b>
									</ion-label>
								</ion-col>
								<ion-col class="valor" size="6">
									<ion-label>
										{{ solicitud.fecha_programacion }}
									</ion-label>
								</ion-col>
							</ion-row>
							<ion-row class="seccion-texto ">
								<ion-col class="dato" size="6" >
									<ion-label class="text-color-primario">
										<b>Descripcion:</b>
									</ion-label>
								</ion-col>
								<ion-col class="valor" size="6">
									<ion-label>
										{{ servicio.descripcion }}
									</ion-label>
								</ion-col>
							</ion-row>
							<ion-row class="seccion-texto ">
								<ion-col class="dato" size="6" >
									<ion-label class="text-color-primario">
										<b>Valor:</b>
									</ion-label>
								</ion-col>
								<ion-col class="valor" size="6">
									<ion-label>
										${{ servicio.valor }}
									</ion-label>
								</ion-col>
							</ion-row>
							<ion-row class="seccion-texto ">
								<ion-col class="dato" size="6" >
									<ion-label class="text-color-primario">
										<b>Bloque:</b>
									</ion-label>
								</ion-col>
								<ion-col class="valor" size="6">
									<ion-label>
										{{ solicitud.bloque == 1 ? "Mañana" : "Tarde"}}
									</ion-label>
								</ion-col>
							</ion-row>


							

							<ion-row class="seccion-estado ">
								<ion-col class="dato" size="12" >
									<ion-label class="cen-vh text-color-primario">
										<b>Estado:</b>
									</ion-label>
								</ion-col>
								<ion-col class="valor" size="12">
									<ion-label class="cen-vh " v-bind:style="colorBackground">
										{{ solicitud.estado.nombre }} 
									</ion-label>
								</ion-col>
							</ion-row>
							
					    </ion-card-content>
				    </ion-card>

					<ion-row class="seccion-titulo ">
						<ion-col class="dato" size="12">
							<ion-label class=" cen-vh text-color-primario">
								<b>Ciente</b>
							</ion-label>
						</ion-col>
					</ion-row>
			        <ion-card class="card-cliente">
			            <ion-card-content class="contenido">
							<ion-row class="seccion-texto ">
								<ion-col class="dato" size="12">
									<ion-label class="text-color-primario">
										<ion-buttons slot="start">
											<ion-icon slot="icon-only" :icon="person"></ion-icon>
											<b>Nombre:</b>
										</ion-buttons>
											
									</ion-label>
								</ion-col>
								<ion-col class="valor" size="12">
									<ion-label>
										{{solicitud.cliente.informacion.nombres}} {{solicitud.cliente.informacion.apellidos}}
									</ion-label>
								</ion-col>
							</ion-row>
							<ion-row class="seccion-texto ">
								<ion-col class="dato" size="12" >
									<ion-label class="text-color-primario">
										
										<ion-buttons slot="start">
											<ion-icon slot="icon-only" :icon="call"></ion-icon>
											<b>Contacto:</b>
										</ion-buttons>
									</ion-label>
								</ion-col>
								<ion-col class="valor" size="12">
									<ion-label>
										<a href="mailto:{{ solicitud.cliente.email }}">
											{{ solicitud.cliente.email }}
										</a> / 
										<a href="tel:{{ solicitud.cliente.informacion.telefono1 }}">
											{{ solicitud.cliente.informacion.telefono1 }}
										</a>
									</ion-label>
								</ion-col>
							</ion-row >
							<ion-row class="seccion-texto ">
								<ion-col class="dato" size="12">
									<ion-label class="text-color-primario">
										<ion-buttons slot="start">
											<ion-icon slot="icon-only" :icon="pin"></ion-icon>
											<b>Dirección:</b>
										</ion-buttons>
									</ion-label>
								</ion-col>
								<ion-col class="valor" size="12">
									<ion-label>
										{{ solicitud.cliente.informacion.direccion }} 
									</ion-label>
								</ion-col>
							</ion-row>
					    </ion-card-content>
				    </ion-card>


					
					<ion-row class="seccion-titulo " v-if="terminada">
						<ion-col class="dato" size="12">
							<ion-label class=" cen-vh text-color-primario">
								<b>Calificación</b>
							</ion-label>
						</ion-col>
					</ion-row>
			        <ion-card class="card-cliente" v-if="terminada">
			            <ion-card-content class="contenido">
							<ion-row class="seccion-calificacion ">
								<ion-col class="dato" size="12" v-if="!mostrarEstrellas">
									<form class="calificacion-form"  @submit.prevent="Calificar">
										<hr/>
										<label class="cen-vh">Seleccione una calificación por la Atención</label>
										<p class="clasificacion-atencion"> 
											
											<input v-model="estrella.clasificacion_atencion" id="radio-atencion-5" type="radio" name="estrellas-atencion" :value="5">
											<label class="estrella-label-atencion" for="radio-atencion-5">★</label>
											<input v-model="estrella.clasificacion_atencion" id="radio-atencion-4" type="radio" name="estrellas-atencion" :value="4">
											<label class="estrella-label-atencion" for="radio-atencion-4">★</label>
											<input v-model="estrella.clasificacion_atencion" id="radio-atencion-3" type="radio" name="estrellas-atencion" :value="3">
											<label class="estrella-label-atencion" for="radio-atencion-3">★</label>
											<input v-model="estrella.clasificacion_atencion" id="radio-atencion-2" type="radio" name="estrellas-atencion" :value="2">
											<label class="estrella-label-atencion" for="radio-atencion-2">★</label>
											<input v-model="estrella.clasificacion_atencion" id="radio-atencion-1" type="radio" name="estrellas-atencion" :value="1">
											<label class="estrella-label-atencion" for="radio-atencion-1">★</label>
										</p>
										
										
										<label class="cen-vh">Seleccione una calificación por la Rapidez</label>
										<p class="clasificacion-rapidez"> 
											
											<input v-model="estrella.clasificacion_rapidez" id="radio-rapidez-5" type="radio" name="estrellas-rapidez" :value="5">
											<label class="estrella-label-rapidez" for="radio-rapidez-5">★</label>
											<input v-model="estrella.clasificacion_rapidez" id="radio-rapidez-4" type="radio" name="estrellas-rapidez" :value="4">
											<label class="estrella-label-rapidez" for="radio-rapidez-4">★</label>
											<input v-model="estrella.clasificacion_rapidez" id="radio-rapidez-3" type="radio" name="estrellas-rapidez" :value="3">
											<label class="estrella-label-rapidez" for="radio-rapidez-3">★</label>
											<input v-model="estrella.clasificacion_rapidez" id="radio-rapidez-2" type="radio" name="estrellas-rapidez" :value="2">
											<label class="estrella-label-rapidez" for="radio-rapidez-2">★</label>
											<input v-model="estrella.clasificacion_rapidez" id="radio-rapidez-1" type="radio" name="estrellas-rapidez" :value="1">
											<label class="estrella-label-rapidez" for="radio-rapidez-1">★</label>
										</p>
										<label class="cen-vh">Seleccione una calificación por el Valor</label>
										<p class="clasificacion-valor"> 
											<input  v-model="estrella.clasificacion_valor" id="radio-valor-5" type="radio" name="estrellas-valor" :value="5">
											<label class="estrella-label-valor" for="radio-valor-5">★</label>
											<input  v-model="estrella.clasificacion_valor" id="radio-valor-4" type="radio" name="estrellas-valor" :value="4">
											<label class="estrella-label-valor" for="radio-valor-4">★</label>
											<input  v-model="estrella.clasificacion_valor" id="radio-valor-3" type="radio" name="estrellas-valor" :value="3">
											<label class="estrella-label-valor" for="radio-valor-3">★</label>
											<input  v-model="estrella.clasificacion_valor" id="radio-valor-2" type="radio" name="estrellas-valor" :value="2">
											<label class="estrella-label-valor" for="radio-valor-2">★</label>
											<input  v-model="estrella.clasificacion_valor" id="radio-valor-1" type="radio" name="estrellas-valor" :value="1">
											<label class="estrella-label-valor" for="radio-valor-1">★</label>
										</p>

					
									
										<span>*Tienes que seleccionar al menos una estrella por opción para que se guarde la calificación</span>
										<ion-row>

											<ion-col size="12" class="col-sep">
												<ion-label class="text-color-primario">
													<b>Comentarios extras</b>
												</ion-label>
											</ion-col>
										</ion-row>
										<ion-row responsive-lg>
											<ion-col size="12" class="col-ssep">
												<ion-item>
													<textarea
													v-model="estrella.comentarios"
													placeholder="Comentarios"
													required
													style="min-width: 100% !important;"
												>
													</textarea>
												</ion-item>
											</ion-col>
												
										</ion-row>


										<ion-row responsive-lg>
											<ion-col>
												<ion-button type="submit" expand="block">
												Calificar
												</ion-button>
											</ion-col>
											</ion-row>
									</form>
								</ion-col>
								<ion-col class="mostrar-estrellas" size="12" v-if="mostrarEstrellas">
									<label  class="cen-vh">Calificación por la Atención</label>
									<p class="clasificacion"> 
										<label v-for="index in (5 - valoracion_atencion)" class="estrella-label-sin" for="radio-valor-5">★</label>
										<label v-for="index in valoracion_atencion" class="estrella-label" for="radio-valor-5">★</label>
									</p>
									<label  class="cen-vh">Calificación por la Rapidez</label>
									<p class="clasificacion"> 
										<label v-for="index in (5 - valoracion_rapidez)" class="estrella-label-sin" for="radio-valor-5">★</label>
										<label v-for="index in valoracion_rapidez" class="estrella-label" for="radio-valor-5">★</label>
									</p>
									<label  class="cen-vh">Calificación por el Valor</label>
									<p class="clasificacion"> 
										<label v-for="index in (5 - valoracion_valor)" class="estrella-label-sin" for="radio-valor-5">★</label>
										<label v-for="index in valoracion_valor" class="estrella-label" for="radio-valor-5">★</label>
									</p>
								</ion-col>
							</ion-row>
					    </ion-card-content>
				    </ion-card>
				</ion-col>
				
			</ion-row>
			<ion-row class="seccion-solicitar" v-if="terminada">
				<ion-col class="dato" size="12">
					<ion-label class=" cen-vh text-color-primario">
						<ion-button v-on:click="SolicitarOtra" type="button">Solicitar De Nuevo</ion-button>
					</ion-label>
				</ion-col>
			</ion-row>
		</div>
	</ion-content>
</ion-page>
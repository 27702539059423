import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/vue';
import ExploreContainer from '@/components/ExploreContainer.vue';

import { 
  exitOutline,
  chevronBackOutline,
  newspaper,
} from 'ionicons/icons';
import { Plugins } from "@capacitor/core";
const { Storage } = Plugins
import { 
  IonButtons, 
  IonMenuButton,
  IonLabel,
  IonCol,
  IonRow,
  IonCardContent,
  IonCard,
  IonItemDivider,
  IonList,
  IonSelectOption,
  IonSelect,
  IonItem,
  IonTextarea,
  IonDatetime,
  toastController,
  IonThumbnail,
  IonImg,
  IonRippleEffect,
  IonButton,
  IonIcon,
  modalController
} from '@ionic/vue';

export default  {
  name: 'solicitudes',
  components: { 
    ExploreContainer, 
    IonMenuButton,
    IonHeader, 
    IonToolbar, 
    IonTitle, 
    IonContent, 
    IonPage,
    IonButtons,
    IonIcon,
    IonLabel,
    IonCol,
    IonRow,
    IonCardContent,
    IonCard,
    IonItemDivider,
    IonList,
    IonSelectOption,
    IonSelect,
    IonItem,
    IonTextarea,
    IonDatetime,
    IonThumbnail,
    IonImg,
    IonRippleEffect,
    IonButton,
    IonIcon,



  },
  setup() {
      
  },  data () {
    return {

    
    }
  },
  mounted() {
     
      let str = this.$route.params.json_recibido;

      const obj = JSON.parse(str);
      console.log(obj.usuario_id)
      if(obj.exito == true){
        Storage.set({
            key: 'usuario_id',
            value: obj.usuario_id
        });
          setTimeout(()=> this.$router.replace({
            path: '/home-cliente/solicitudes'
          }),4000);
      }else{
          
      }

  }
}


import { 
  IonTabBar, 
  IonTabButton, 
  IonTabs,
  IonLabel, 
  IonIcon, 
  IonPage 
} from '@ionic/vue';
import { 
  ellipse, 
  square, 
  triangle, 
  cogOutline, 
  newspaper,
  personCircleOutline,
  checkboxOutline
} from 'ionicons/icons';
import { 
  IonButtons, 
  IonMenuButton 
} from '@ionic/vue';

import eventBus from '../../eventBus.js';

export default {
  name: 'home',
  components: { 
    IonLabel, 
    IonTabs, IonTabBar, 
    IonTabButton, 
    IonIcon, 
    IonPage,
    IonButtons, 
    IonMenuButton  
  },
  setup() {
    return {
      ellipse, 
      square, 
      triangle,
      cogOutline, 
      newspaper,
      checkboxOutline,
      personCircleOutline
    }
  },
  props: [],
  data () {
    return {
      username: "",
      password: "",
      persona_contacto: "",
      empresa: "",
      rut: "",
      telefono: "",
      comuna: "",
      direccion: "",
      submitted: false,
      flagform_log: 0,
      flagform_reg: false
    }
  },
  computed: {

  },
  mounted () {
    window.OneSignal = window.OneSignal || [];
		let myCustomUniqueUserId = localStorage.getItem('_cap_usuario_id');
		OneSignal.push(function() {
			OneSignal.init({
			appId: "a025a9b0-7dda-40ac-8077-8ce098daddd0",
			notifyButton: {
				enable: true,
			},
			promptOptions: {
				slidedown: {
				enabled: true,
				autoPrompt: true,
				timeDelay: 20,
				pageViews: 3,
				actionMessage: "Nos gustaria mostrarte notificaciones para poder mantenerte al tanto de tus solicitudes.",
				acceptButtonText: "Permitir",
				cancelButtonText: "No Gracias",
				}
			}
			});
			OneSignal.setExternalUserId(myCustomUniqueUserId);
		});
  },
  methods: {
    beforeTabChange() {
     
    },

    afterTabChange() {
      
    },
    updateSolicitudes(){
      eventBus.$emit('update-solicitudes-proveedor');
    },
    updateRealizadas(){
      eventBus.$emit('update-solicitudes-realizadas-proveedor');
    }
  }
}





import { Plugins } from "@capacitor/core";
const { Storage } = Plugins

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonInput,
  IonItem,
  IonCol,
  IonRow,
  IonButton,
  IonCardContent,
  IonCard,
  IonToggle,
  IonDatetime,
  IonSelect,
  alertController,
  modalController,
  toastController
} from '@ionic/vue';
import ExploreContainer from '@/components/ExploreContainer.vue';
import TerminosModal from '../../modals/terminos/index.vue';
export default {
  name: 'registro',
  components: {
    ExploreContainer,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonList,
    IonInput,
    IonItem,
    IonCol,
    IonButton,
    IonRow,
    IonCardContent,
    IonCard,
    IonToggle,
    IonDatetime,
    IonSelect
  },
  props: [],
  data() {
    return {
      username: "",
      password: "",
      persona_contacto: "",
      empresa: "",
      rut: "",
      telefono: "",
      subzona: "",
      direccion: "",
      submitted: false,
      flagform_log: 0,
      flagform_reg: false,
      usuario: {
        despacho: false,
      },
      zonas: {},
      subzonas: {},
      status: {},
      errors: {},
    }
  },
  computed: {

  },
  mounted() {
    this.ObtenerRegiones();
  },
  methods: {

    async ModalTerminos() {
      const modal = await modalController
        .create({
          component: TerminosModal,
          cssClass: 'my-custom-class',
          componentProps: {
            title: 'New Title'
          }
        })
      return modal.present();
    },

    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 2000,
        position: 'top',
      })
      return toast.present();
    },


    ObtenerSubzonas(select) {
      console.log(select.target.value);
      var formData = new window.FormData();

      window.http.get(
        process.env.VUE_APP_URL + "/api/subzonas/getbyzona/" + select.target.value,
        formData,
        window.headers
      ).then(
        res => {
          this.subzonas = res.data
          console.log(res.data)
        }
      ).catch(
        err => {
          if (err.status === 500) {
            this.status = err.data.status
          }

          if (err.status === 422) {
            this.errors = err.data.errors
          }

          if (err.status === 401) {
            this.errors = err.data.errors
          }
          console.log(err)
        }
      );
    },

    RedirectDonde(rol) {
      var ruta_default = "";
      if (rol == 2) { // proveedor y admin
        ruta_default = '/home-proveedor/';
      } else if (rol == 3) { // cliente y admin
        ruta_default = '/home-cliente/';
      } else {
        ruta_default = null;
      }
      return ruta_default;
    },

    Registrar(e) {
      e.preventDefault();
      var formData = new window.FormData();
    
      if(this.usuario.complemento === undefined){
        this.usuario.complemento =''
      }
      console.log(this.usuario.complemento)
      console.log(this.usuario.dv)
      if (this.usuario.password != this.usuario.passwordConfirmation) {
				this.openToast("La contraseña y su confirmación no coinciden");
      } else {

        formData.append(
          "usuario",
          this.usuario.usuario
        );

        formData.append(
          "despacho",
          this.usuario.despacho
        );

        formData.append(
          "email",
          this.usuario.usuario
        );

        formData.append(
          "password",
          this.usuario.password
        );

        formData.append(
          "rut",
          this.usuario.rut
        );

        formData.append(
          "dv",
          this.usuario.dv
        );

        formData.append(
          "subzona_id",
          this.usuario.subzona_id
        );

        formData.append(
          "zona_id",
          this.usuario.zona_id
        );

        formData.append(
          "nombres",
          this.usuario.nombres
        );

        formData.append(
          "apellidos",
          this.usuario.apellidos
        );

        var fecha = this.usuario.fecha_nacimiento.split("T");
        fecha = fecha[0] + " " + fecha[1].split(".")[0];
        formData.append(
          "fecha_nacimiento",
          fecha
        );

        formData.append(
          "direccion",
          this.usuario.direccion
        );

        formData.append(
          "numero",
          this.usuario.numero
        );

        formData.append(
          "complemento",
          this.usuario.complemento == 'undefined' ?'':this.usuario.complemento
        );

        formData.append(
          "telefono1",
          this.usuario.telefono1
        );

        formData.append(
          "telefono2",
          this.usuario.telefono2
        );

        formData.append(
          "empresa",
          this.usuario.empresa
        );

        window.http.post(
          process.env.VUE_APP_URL + "/api/usuario/crear-actualizar",
          formData,
          window.headers
        ).then(
          res => {
            console.log(res)
            this.openToast(res.data.msg);
            if (res.data.exito == true)
              if (res.data.login.exito == true) {
                var token = res.data.login.token
                Storage.set({
                  key: 'tok_code',
                  value: token
                });

                Storage.set({
                  key: 'rol',
                  value: res.data.login.rol
                });

                Storage.set({
                  key: 'usuario_id',
                  value: res.data.login.usuario_id
                });

                window.headers.headers["Authorization"] = "Bearer" + token;
                var ruta = this.RedirectDonde(res.data.login.rol)
                if (ruta != null) {
                  this.$router.replace({
                    path: ruta
                  })
                }
              }
          }
        ).catch(
          err => {
            if (err.status === 500) {
              this.status = err.data.status
            }

            if (err.status === 422) {
              this.errors = err.data.errors
            }

            if (err.status === 401) {
              this.errors = err.data.errors
            }
            console.log(err)
          }
        );
      };
    },

    ObtenerRegiones() {
      console.log(this.servicio)
      var formData = new window.FormData();

      window.http.get(
        process.env.VUE_APP_URL + "/api/zonas/get",
        formData,
        window.headers
      ).then(
        res => {
          this.zonas = res.data
          console.log(res.data)
        }
      ).catch(
        err => {
          if (err.status === 500) {
            this.status = err.data.status
          }

          if (err.status === 422) {
            this.errors = err.data.errors
          }

          if (err.status === 401) {
            this.errors = err.data.errors
          }
          console.log(err)
        }
      );
    },
  }
}





<ion-page>
	<ion-header>
		<ion-toolbar>
			<ion-buttons slot="start">
				<ion-button class="btn-exit" @click="Back">
					<ion-icon slot="icon-only" :icon="chevronBackOutline"></ion-icon>
				</ion-button>
			</ion-buttons>
			<ion-buttons slot="start">
				<h5 class="titulo-head">Detalle servicio</h5>
			</ion-buttons>
		</ion-toolbar>
	</ion-header>
	<ion-content>
		<div class="contenido-home">
			<ion-row responsive-lg>
				<ion-col>
					<ion-card class="card-terminos">
						<ion-card-content>
							<ion-row>
								<ion-col size="12">
									<ion-label class="text-color-primario">
										<b>Servicio</b>
									</ion-label>
								</ion-col>
								<ion-col size="12">
									<ion-label>
										{{ servicio.nombre }}
									</ion-label>
								</ion-col>
							</ion-row>
							<ion-row>
								<ion-col size="12">
									<ion-label class="text-color-primario">
										<b>Descripción</b>
									</ion-label>
								</ion-col>
								<ion-col size="12">
									<ion-label>
										{{ servicio.descripcion }}
									</ion-label>
								</ion-col>
							</ion-row>

							<ion-row>
								<ion-col size="12" class="col-sep">
									<ion-label class="text-color-primario">
										<b>Valor</b>
									</ion-label>
								</ion-col>
								<ion-col size="12">
									<ion-label>
										${{ servicio.valor }}
									</ion-label>
								</ion-col>
							</ion-row>
							<ion-row>
								<ion-col size="12" class="col-sep">
									<ion-label class="text-color-primario">
										<b>Bloques</b>
									</ion-label>
								</ion-col>
								<ion-col size="12">
									<ion-label>
										{{ servicio.manana == 1 ? "Mañana" : ""}}
										{{ servicio.tarde == 1 ? "Tarde" : ""}}
									</ion-label>
								</ion-col>
							</ion-row>

							<ion-card
								style="height: 130px; width: 100%; margin-left: auto; margin-right: auto; text-align: center;">
								<ion-card-header>
									<p style="font-size: 1.3em; text-align: center;">
										<ion-label>
											Disponibilidad
										</ion-label>
									</p>
								</ion-card-header>
								<ion-card-content>
									<ion-row style="font-size: 0.53em; border-bottom: groove;">
										<ion-col>
											<ion-label>Lun</ion-label>
										</ion-col>
										<ion-col>
											<ion-label>Mar</ion-label>
										</ion-col>
										<ion-col>
											<ion-label>Mié</ion-label>
										</ion-col>
										<ion-col>
											<ion-label>Jue</ion-label>
										</ion-col>
										<ion-col>
											<ion-label>Vie</ion-label>
										</ion-col>
										<ion-col>
											<ion-label>Sáb</ion-label>
										</ion-col>
										<ion-col>
											<ion-label>Dom</ion-label>
										</ion-col>
									</ion-row>
									<ion-row style="text-align: center;">
										<ion-col v-if="servicio.disponibilidad.includes('Lun')">
											<ion-icon :icon="checkmark" style="color: green;" />
										</ion-col>
										<ion-col v-else>
											<ion-icon :icon="ban" style="color: red;" />
										</ion-col>
										<ion-col v-if="servicio.disponibilidad.includes('Mar')">
											<ion-icon :icon="checkmark" style="color: green;" />
										</ion-col>
										<ion-col v-else>
											<ion-icon :icon="ban" style="color: red;" />
										</ion-col>
										<ion-col v-if="servicio.disponibilidad.includes('Mié')">
											<ion-icon :icon="checkmark" style="color: green;" />
										</ion-col>
										<ion-col v-else>
											<ion-icon :icon="ban" style="color: red;" />
										</ion-col>
										<ion-col v-if="servicio.disponibilidad.includes('Jue')">
											<ion-icon :icon="checkmark" style="color: green;" />
										</ion-col>
										<ion-col v-else>
											<ion-icon :icon="ban" style="color: red;" />
										</ion-col>
										<ion-col v-if="servicio.disponibilidad.includes('Vie')">
											<ion-icon :icon="checkmark" style="color: green;" />
										</ion-col>
										<ion-col v-else>
											<ion-icon :icon="ban" style="color: red;" />
										</ion-col>
										<ion-col v-if="servicio.disponibilidad.includes('Sáb')">
											<ion-icon :icon="checkmark" style="color: green;" />
										</ion-col>
										<ion-col v-else>
											<ion-icon :icon="ban" style="color: red;" />
										</ion-col>
										<ion-col v-if="servicio.disponibilidad.includes('Dom')">
											<ion-icon :icon="checkmark" style="color: green;" />
										</ion-col>
										<ion-col v-else>
											<ion-icon :icon="ban" style="color: red;" />
										</ion-col>
									</ion-row>

									 <!-- <p class="cen-v">
										<ion-label>
											{{ servicio.disponibilidad }}
										</ion-label>
									</p> 	 -->
								</ion-card-content>
							</ion-card>
							
							<ion-row>
								<ion-col size="12" class="col-sep">
									<ion-label class="text-color-primario">
										<ion-button 
											@click="ServicioPagar(servicio)" 
										expand="block">
											Solicitar
										</ion-button>
									</ion-label>
								</ion-col>
							</ion-row>
						</ion-card-content>
					</ion-card>
				</ion-col>
			</ion-row>
		</div>
	</ion-content>
</ion-page> 
<ion-page>
  <ion-header>
    <ion-toolbar color="primary">
      <ion-buttons slot="start">
        <ion-menu-button></ion-menu-button>
      </ion-buttons>
      <ion-title>Login</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content fullscreen="true">
    <div class="content">
      <div class="reg">
        <div class="login-logo">
          <img class="logo" src="/assets/logo/logo.png" alt="Ionic logo">
        </div>

        <form @submit.prevent="Registrar" class="form-login">
          <ion-list>
            <ion-row responsive-lg>
              <ion-col>
                <ion-item class="ion-no-padding">
                  <ion-input v-model="usuario.usuario" name="usuario" type="text" spellcheck="false"
                    autocapitalize="off" placeholder="Correo electrónico" required></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>

            <ion-row responsive-lg>
              <ion-col size="7">
                <ion-item class="ion-no-padding">
                  <ion-input v-model="usuario.rut" placeholder="RUT" name="rut" type="number" maxlength="8" required>
                  </ion-input>
                </ion-item>
              </ion-col>
              <ion-col size="2">
                <ion-item class="ion-no-padding">
                  <ion-input readonly v-model="usuario.guion" placeholder="-" name="rut" type="text" maxlength="4" required style="padding-left: 5px !important;">
                  </ion-input>
                </ion-item>
              </ion-col>
              <ion-col size="3">
                <ion-item class="ion-no-padding">
                  <ion-input  v-model="usuario.dv" name="D" maxlength="1" type="text" required>
                  </ion-input>
                </ion-item>
              </ion-col>
            </ion-row>

            <ion-row responsive-lg>
              <ion-col size="6">
                <ion-item class="ion-no-padding">
                  <ion-input v-model="usuario.nombres" name="nombres" type="text" spellcheck="false"
                    autocapitalize="off" placeholder="Nombres" required></ion-input>
                </ion-item>
              </ion-col>

              <ion-col size="6">
                <ion-item class="ion-no-padding">
                  <ion-input v-model="usuario.apellidos" name="apellidos" type="text" spellcheck="false"
                    autocapitalize="off" placeholder="Apellidos" required></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>

            <ion-row responsive-lg>
              <ion-col size="12">
                <ion-item class="ion-no-padding">
                  <ion-datetime v-model="usuario.fecha_nacimiento" placeholder="Fecha de nacimiento" display-timezone="utc"
                    monthShortNames="Ene, Feb, Mar, Abril, Mayo, Jun, Jul, Ago, Sep, Oct, Nov, Dic"
                    displayFormat="DD de MMM, del YYYY">
                  </ion-datetime>
                </ion-item>
              </ion-col>
            </ion-row>

            <ion-row responsive-lg>
              <ion-col size="6">
                <ion-item class="ion-no-padding">
                  <ion-input v-model="usuario.telefono1" placeholder="Teléfono" name="telefono1" type="number" required>
                  </ion-input>
                </ion-item>
              </ion-col>

              <ion-col size="6">
                <ion-item class="ion-no-padding">
                  <ion-input v-model="usuario.telefono2" placeholder="Otro teléfono" name="telefono2" type="number">
                  </ion-input>
                </ion-item>
              </ion-col>
            </ion-row>

            <ion-row responsive-lg>
              <ion-col size="6">
                <ion-item class="ion-no-padding">
                  <ion-select required @ionChange="ObtenerSubzonas($event)" v-model="usuario.zona_id"
                    placeholder="Zona">
                    <ion-select-option v-for="zona in zonas" :value="zona.id">
                      {{ zona.nombre }}
                    </ion-select-option>
                  </ion-select>
                </ion-item>
              </ion-col>
              <ion-col size="6">
                <ion-item class="ion-no-padding">
                  <ion-select v-model="usuario.subzona_id" placeholder="Subzona">
                    <ion-select-option v-for="subzona in subzonas" :value="subzona.id">
                      {{ subzona.nombre }}
                    </ion-select-option>
                  </ion-select>
                </ion-item>
              </ion-col>
            </ion-row>

            <ion-row responsive-lg>
              <ion-col size="12">
                <ion-item class="ion-no-padding">
                  <ion-input v-model="usuario.direccion" placeholder="Dirección" name="direccion" type="text" required>
                  </ion-input>
                </ion-item>
              </ion-col>
              <ion-col size="12">
                <ion-item class="ion-no-padding">
                  <ion-input v-model="usuario.numero" placeholder="Número" name="numero" type="number" required>
                  </ion-input>
                </ion-item>
              </ion-col>
              <ion-col size="12">
                <ion-item class="ion-no-padding">
                  <ion-input v-model="usuario.complemento" placeholder="Complemento (Opcional): Número dept, condominio, oficina, piso, etc" name="complemento" type="text" >
                  </ion-input>
                </ion-item>
              </ion-col>
            </ion-row>

            <ion-row responsive-lg>
              <ion-col>
                <ion-item class="ion-no-padding">
                  <ion-input v-model="usuario.password" placeholder="Contraseña" name="password" type="password"
                    required>
                  </ion-input>
                </ion-item>
              </ion-col>
            </ion-row>

            <ion-row responsive-lg>
              <ion-col>
                <ion-item class="ion-no-padding">
                  <ion-input v-model="usuario.passwordConfirmation" placeholder="Confirmar Contraseña"
                    name="passwordconfirmation" type="password" required>
                  </ion-input>
                </ion-item>
              </ion-col>
            </ion-row>

            <ion-row responsive-lg>
              <ion-col>
                <ion-card>
                  <ion-card-content>
                    <ion-row>
                      <ion-col size="9" class="col-sep">
                        <ion-button class="terminos-btn" @click="ModalTerminos()" expand="block" type="button">
                          Terminos y Condiciones
                        </ion-button>
                      </ion-col>
                      <ion-col size="3">
                        <div class="cen-vh">
                          <ion-toggle></ion-toggle>
                        </div>
                      </ion-col>
                    </ion-row>
                  </ion-card-content>
                </ion-card>
              </ion-col>
            </ion-row>

            <ion-row responsive-lg>
              <ion-col>
                <ion-button type="submit" expand="block">
                  Registrar
                </ion-button>
              </ion-col>
            </ion-row>

     

          </ion-list>
        </form>
      </div>
    </div>
    <ExploreContainer name="Tab 2 page" />
  </ion-content>
</ion-page>
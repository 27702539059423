import { 
	IonPage, 
	IonHeader, 
	IonToolbar, 
	IonTitle, 
	IonContent, 
	IonList, 
	IonInput, 
	IonItem, 
	IonCol, 
	IonRow, 
	IonButton,
	IonMenuButton, 
	IonButtons,
	toastController
} from '@ionic/vue';

import Usuario from "../../../modelos/Usuario.js";
import ExploreContainer from '@/components/ExploreContainer.vue';
import { Plugins } from "@capacitor/core";
const { Storage } = Plugins

export default  {
	name: 'login',
	components: { 
		ExploreContainer, 
		IonHeader, 
		IonToolbar, 
		IonTitle, 
		IonContent, 
		IonPage,
		IonList,
		IonInput,
		IonItem,
		IonCol,
		IonButton,
		IonRow,
		IonMenuButton,
		IonButtons
	},
	props: [],
	data () {
		return {
			username: "",
			password: "",
			persona_contacto: "",
			empresa: "",
			rut: "",
			telefono: "",
			comuna: "",
			direccion: "",
			submitted: false,
			flagform_log: 0,
			flagform_reg: false,
			status: {},
			errors: {},
			msg_error: ""
		}
	},
	computed: {

	},
	mounted () {
		
	},
	created() {
		if(localStorage.getItem('_cap_pago')){
			console.log('entro logout')
			this.Logout();
			localStorage.removeItem('_cap_pago');
		}

		var ruta = this.RedirectDonde(localStorage.getItem("_cap_rol"))
		if (ruta != null) {
			this.$router.replace({ 
				path: ruta 
			})
		}
	},
	methods: {
		async openToast(msg) {
			const toast = await toastController.create({
				message: msg,
				duration: 2000,
				position: 'top',
			})
			return toast.present();
		},
		async openToastOptions() {
			const toast = await toastController.create({
				header: 'Toast header',
				message: 'Click to Close',
				position: 'center',
				buttons: [
					{
						side: 'start',
						icon: 'star',
						text: 'Favorite',
						handler: () => {
						console.log('Favorite clicked');
						alert(45)
						}
					}, {
						text: 'Done',
						role: 'cancel',
						handler: () => {
							console.log('Cancel clicked');
						}
					}
				]
			})
			return toast.present();
		},

		RedirectDonde(rol) {
			var ruta_default = "";
			if (rol == 2) { // proveedor y admin
				ruta_default = '/home-proveedor/';
			} else if (rol == 3) { // cliente y admin
				ruta_default = '/home-cliente/';
			} else {
				ruta_default = null;
			}
			return ruta_default;
		},

		Login(e) {
			e.preventDefault();
			var usuario = new Usuario();
			var formData = new window.FormData();

			formData.append(
				'usuario', 
				this.username
			);

			formData.append(
				'password', 
				this.password
			);

			window.http.post(
				process.env.VUE_APP_URL + "/api/login", 
				formData
			).then(
				res => {
					console.log(res)
					if (res.data.exito == true) {
						var token = res.data.token
						Storage.set({
							key: 'tok_code',
							value: token
						});

						Storage.set({
							key: 'rol',
							value: res.data.rol
						});

						Storage.set({
							key: 'usuario_id',
							value: res.data.usuario_id
						});
						window.headers.headers["Authorization"] = "Bearer" + token;
						var ruta = this.RedirectDonde(res.data.rol)
						if (ruta != null) {
							this.$router.replace({ 
								path: ruta 
							})
						}
					} else {
						this.openToast(res.data.msg)
					}
				}
			).catch(
				err => {
					if (err.status === 500) {
						this.msg_error = "Error 500: Servidor no responde"
					}

					if (err.status === 422) {
						this.msg_error = "Error 422: Entidad inprocesable"
					}

					if (err.status === 401) {
						this.msg_error = "Error 401: No tiene autorizacion"
					}
					this.openToast(this.msg_error)
					console.log(err)
				}
			);
		},
		LoginLocal(token,usuario_id,rol) {
			console.log('entro login')
			
			Storage.set({
				key: 'tok_code',
				value: token
			});

			Storage.set({
				key: 'rol',
				value: rol
			});

			Storage.set({
				key: 'usuario_id',
				value: usuario_id
			});
			window.headers.headers["Authorization"] = "Bearer" + token;
			var ruta = this.RedirectDonde(rol)
			if (ruta != null) {
				console.log('entro ruta')
				this.$router.replace({ 
					path: ruta 
				})
			}
				
		},
		Logout() {
	
			var token = localStorage.getItem('_cap_tok_code');
			var usuario_id = localStorage.getItem('_cap_usuario_id');
			var rol = localStorage.getItem('_cap_rol');

			var formData = new window.FormData();
	
			window.http.post(
			  process.env.VUE_APP_URL + "/api/logout", 
			  formData,
			  window.headers
			).then(
			  res => {
				console.log(res)
				if (res.data.exito == true) {
					console.log('entro logout true')
				  localStorage.removeItem('_cap_tok_code');
				  localStorage.removeItem('_cap_usuario_id');
				  localStorage.removeItem('_cap_rol');
				  this.LoginLocal(token,usuario_id,rol)

				} else {
				  this.openToast(res.data.msg)
				}
			  }
			).catch(
			  err => {
				if (err.status === 500) {
				  this.status = err.data.status
				}
	
				if (err.status === 422) {
				  this.errors = err.data.errors
				}
	
				if (err.status === 401) {
				  this.errors = err.data.errors
				}
				console.log(err)
			  }
			);
		  }
	}
}
<ion-page>
	<ion-header>
		<ion-toolbar>
			<ion-buttons slot="start">
		        <ion-button class="btn-exit" @click="Back">
			        <ion-icon slot="icon-only" :icon="chevronBackOutline"></ion-icon>
			    </ion-button>
		    </ion-buttons>
			<ion-buttons slot="start">
				<h5 class="titulo-head">Detalle servicio</h5>
		    </ion-buttons>
		</ion-toolbar>
	</ion-header>
	<ion-content>
		<div class="contenido-home">
			<ion-row responsive-lg>
		        <ion-col>
					<ion-row class="seccion-titulo ">
						<ion-col class="dato" size="12">
							<ion-label class=" cen-vh text-color-primario">
								<b>Servicio</b>
							</ion-label>
						</ion-col>
					</ion-row>
			        <ion-card class="card-servicio">
			            <ion-card-content class="contenido">
							<ion-row class="seccion-texto ">
								<ion-col class="dato" size="6">
									<ion-label class="text-color-primario">
										<b>Solicitud de:</b>
									</ion-label>
								</ion-col>
								<ion-col class="valor" size="6">
									<ion-label>
										{{ servicio.nombre }}
									</ion-label>
								</ion-col>
							</ion-row>
							<ion-row class="seccion-texto ">
								<ion-col class="dato" size="6" >
									<ion-label class="text-color-primario">
										<b>Categoría:</b>
									</ion-label>
								</ion-col>
								<ion-col class="valor" size="6">
									<ion-label>
										{{ servicio.categoria.nombre }}
									</ion-label>
								</ion-col>
							</ion-row >
							<ion-row class="seccion-texto ">
								<ion-col class="dato" size="6">
									<ion-label class="text-color-primario">
										<b>Programado:</b>
									</ion-label>
								</ion-col>
								<ion-col class="valor" size="6">
									<ion-label>
										{{ solicitud.fecha_programacion }}
									</ion-label>
								</ion-col>
							</ion-row>
							<ion-row class="seccion-texto ">
								<ion-col class="dato" size="6" >
									<ion-label class="text-color-primario">
										<b>Descripcion:</b>
									</ion-label>
								</ion-col>
								<ion-col class="valor" size="6">
									<ion-label>
										{{ servicio.descripcion }}
									</ion-label>
								</ion-col>
							</ion-row>
							<ion-row class="seccion-texto ">
								<ion-col class="dato" size="6" >
									<ion-label class="text-color-primario">
										<b>Valor:</b>
									</ion-label>
								</ion-col>
								<ion-col class="valor" size="6">
									<ion-label>
										${{ servicio.valor }}
									</ion-label>
								</ion-col>
							</ion-row>
							<ion-row class="seccion-texto ">
								<ion-col class="dato" size="6" >
									<ion-label class="text-color-primario">
										<b>Bloque:</b>
									</ion-label>
								</ion-col>
								<ion-col class="valor" size="6">
									<ion-label>
										{{ solicitud.bloque == 1 ? "Mañana" : "Tarde"}}
									</ion-label>
								</ion-col>
							</ion-row>

							<ion-row class="seccion-estado ">
								<ion-col class="dato" size="12" >
									<ion-label class="cen-vh text-color-primario">
										<b>Estado:</b>
									</ion-label>
								</ion-col>
								<ion-col class="valor" size="12">
									<ion-label class="cen-vh "  v-bind:style="colorBackground">
										{{ solicitud.estado.nombre }} 
									</ion-label>
								</ion-col>
							</ion-row>
							
							
					    </ion-card-content>
				    </ion-card>

					<ion-row class="seccion-titulo ">
						<ion-col class="dato" size="12">
							<ion-label class=" cen-vh text-color-primario">
								<b>Cliente</b>
							</ion-label>
						</ion-col>
					</ion-row>
			        <ion-card class="card-cliente">
			            <ion-card-content class="contenido">
							<ion-row class="seccion-texto ">
								<ion-col class="dato" size="12">
									<ion-label class="text-color-primario">
										<ion-buttons slot="start">
											<ion-icon slot="icon-only" :icon="person"></ion-icon>
											<b>Nombre:</b>
										</ion-buttons>
											
									</ion-label>
								</ion-col>
								<ion-col class="valor" size="12">
									<ion-label>
										{{solicitud.cliente.informacion.nombres}} {{solicitud.cliente.informacion.apellidos}}
									</ion-label>
								</ion-col>
							</ion-row>
							<ion-row class="seccion-texto ">
								<ion-col class="dato" size="12" >
									<ion-label class="text-color-primario">
										
										<ion-buttons slot="start">
											<ion-icon slot="icon-only" :icon="call"></ion-icon>
											<b>Contacto:</b>
										</ion-buttons>
									</ion-label>
								</ion-col>
								<ion-col class="valor" size="12">
									<ion-label>
										<a href="mailto:{{ solicitud.cliente.email }}">
											{{ solicitud.cliente.email }}
										</a> / 
										<a href="tel:{{ solicitud.cliente.informacion.telefono1 }}">
											{{ solicitud.cliente.informacion.telefono1 }}
										</a>
									</ion-label>
								</ion-col>
							</ion-row >
							<ion-row class="seccion-texto ">
								<ion-col class="dato" size="12">
									<ion-label class="text-color-primario">
										<ion-buttons slot="start">
											<ion-icon slot="icon-only" :icon="pin"></ion-icon>
											<b>Dirección:</b>
										</ion-buttons>
									</ion-label>
								</ion-col>
								<ion-col class="valor" size="12">
									<ion-label>
										{{ solicitud.cliente.informacion.direccion }} 
									</ion-label>
								</ion-col>
							</ion-row>
					    </ion-card-content>
				    </ion-card>

						<ion-row v-if="!terminada" style="margin-top: 30px;">
							<ion-col size="12">
								<ion-button class="boton-notificacion" @click="Llegue" type="button" expand="block">
									<ion-row>
										<ion-col size="12">
											<ion-buttons class="cen-vh" >
												<ion-icon slot="icon-only" :icon="pin"></ion-icon>
											</ion-buttons>
										</ion-col>
										<ion-col size="12">
											<ion-label class="cen-vh">
												<p>Llegue</p>
											</ion-label>
										</ion-col>
									</ion-row>
								</ion-button>
							</ion-col>
						</ion-row>

					<ion-row style="margin-top: 10px;" v-if="!terminada">
						<ion-col size="6">
							<ion-button class="boton-notificacion" @click="ConfigurarSolicitudEstadoInicio" type="button" expand="block">
								<ion-row>
									<ion-col size="12">
										<ion-buttons class="cen-vh" >
											<ion-icon slot="icon-only" :icon="flagOutline"></ion-icon>
										</ion-buttons>
									</ion-col>
									<ion-col size="12">
										<ion-label class="cen-vh">
											<p>Inicio</p>
										</ion-label>
									</ion-col>
								</ion-row>
							</ion-button>
						</ion-col>
						
						<ion-col size="6">
							<ion-button class="boton-notificacion" @click="ConfigurarSolicitudEstado" type="button" expand="block">
								<ion-row>
									<ion-col size="12">
										<ion-buttons class="cen-vh" >
											<ion-icon slot="icon-only" :icon="flag"></ion-icon>
										</ion-buttons>
									</ion-col>
									<ion-col size="12">
										<ion-label class="cen-vh">
											<p>Fin</p>
										</ion-label>
									</ion-col>
								</ion-row>
							</ion-button>
						</ion-col>
					</ion-row>

					<ion-row class="seccion-titulo " v-if="mostrarEstrellas && terminada">
						<ion-col class="dato" size="12">
							<ion-label class=" cen-vh text-color-primario">
								<b>Calificación</b>
							</ion-label>
						</ion-col>
					</ion-row>
			        <ion-card class="card-cliente" v-if="mostrarEstrellas && terminada">
			            <ion-card-content class="contenido">
							<ion-row class="seccion-calificacion ">
								<ion-col class="dato" size="12" v-if="mostrarEstrellas">
									<form class="calificacion-form"  @submit.prevent="Calificar">
										<hr/>

										<label class="cen-vh">Seleccione una calificación para el Cliente</label>

										<p class="clasificacion-cliente"> 
											
											<input v-model="estrella.calificacion_cliente" id="radio-cliente-5" type="radio" name="estrellas-cliente" :value="5">
											<label class="estrella-label-cliente" for="radio-cliente-5">★</label>
											<input v-model="estrella.calificacion_cliente" id="radio-cliente-4" type="radio" name="estrellas-cliente" :value="4">
											<label class="estrella-label-cliente" for="radio-cliente-4">★</label>
											<input v-model="estrella.calificacion_cliente" id="radio-cliente-3" type="radio" name="estrellas-cliente" :value="3">
											<label class="estrella-label-cliente" for="radio-cliente-3">★</label>
											<input v-model="estrella.calificacion_cliente" id="radio-cliente-2" type="radio" name="estrellas-cliente" :value="2">
											<label class="estrella-label-cliente" for="radio-cliente-2">★</label>
											<input v-model="estrella.calificacion_cliente" id="radio-cliente-1" type="radio" name="estrellas-cliente" :value="1">
											<label class="estrella-label-cliente" for="radio-cliente-1">★</label>
										</p>
										
										<span>*Tienes que seleccionar al menos una estrella para que se guarde la calificación</span>
										
										<ion-row>

											<ion-col size="12" class="col-sep">
												<ion-label class="text-color-primario">
													<b>Comentarios extras</b>
												</ion-label>
											</ion-col>
										</ion-row>
										<ion-row responsive-lg>
											<ion-col size="12" class="col-ssep">
												<ion-item>
													<textarea
													v-model="estrella.comentarios"
													placeholder="Comentarios"
													required
													style="min-width: 100% !important;"
												>
													</textarea>
												</ion-item>
											</ion-col>
												
										</ion-row>
										<ion-row responsive-lg>
											<ion-col>
												<ion-button type="submit" expand="block">
												Calificar
												</ion-button>
											</ion-col>
										</ion-row>
									</form>
								</ion-col>
								
							</ion-row>
					    </ion-card-content>
				    </ion-card>
				</ion-col>
			</ion-row>
		</div>
	</ion-content>
</ion-page>
<ion-page>
	<ion-header>
		<ion-toolbar>
			<ion-buttons slot="start">
				<ion-button class="btn-exit" @click="Back">
					<ion-icon slot="icon-only" :icon="chevronBackOutline"></ion-icon>
				</ion-button>
			</ion-buttons>
			<ion-buttons slot="start">
				<h5 class="titulo-head">{{ categoria.nombre }}</h5>
			</ion-buttons>
		</ion-toolbar>
	</ion-header>
	<ion-content :fullscreen="true">
		<div class="contenido-home">
			<div class="cuerpo" >
				<ion-row responsive-lg v-if="numeroComida != 0">
			        <ion-col>
						<div  style="margin: 20px 10px;">
							<form @submit.prevent="Filtrar" >
								<ion-row responsive-lg>
									<ion-col size="12" class="col-sep">
										<ion-item class="filtro">
											<ion-label>
												Peso :
											</ion-label>
											<ion-select v-model="buscar.kilo"  placeholder="Kilo">
												<ion-select-option v-for="kilo in comidaPerro.kilos" :value="kilo.id">
													{{kilo.nombre}}
												</ion-select-option>
											</ion-select>
										</ion-item>
										<ion-item class="filtro">
											<ion-label>
												Marca :
											</ion-label>
											<ion-select v-model="buscar.marca"  placeholder="Marca">
												<ion-select-option v-for="marca in comidaPerro.marcas" :value="marca.id">
													{{marca.nombre}}
												</ion-select-option>
											</ion-select>
										</ion-item>
										<ion-item class="filtro">
											<ion-label>
												Raza :
											</ion-label>
											<ion-select v-model="buscar.raza"  placeholder="Raza">
												<ion-select-option v-for="raza in comidaPerro.razas" :value="raza.id">
													{{raza.nombre}}
												</ion-select-option>
											</ion-select>
										</ion-item>
									</ion-col>
									<ion-col  size="6" class="col-sep">
										<ion-button class="boton-crear-principal" type="submit" >Filtrar</ion-button>
									</ion-col>
									<ion-col  size="6" class="col-sep">
										<ion-button v-on:click="Borrarfiltro" class="boton-crear-principal" type="button" >Borrar Filtro</ion-button>
									</ion-col>
								</ion-row>
							</form>
						</div>
			        </ion-col>
			    </ion-row>
			    <ion-item-divider>
			      	<ion-label>Servicios disponibles</ion-label>
			    </ion-item-divider>
			    <ion-list>
					<div v-for="serv in servicios">
						<ion-ripple-effect type="unbounded"></ion-ripple-effect>
						<ion-item button @click="ModalDetalleServicio(serv)" lines="none" class="item-servicios">	
					    	<div class="elem-item" style="padding-right: 2em;">
					    		<ion-thumbnail slot="start">
						        	<ion-img class="icon-serv" :src="categoria.imagen"></ion-img>
						    	</ion-thumbnail>
					    	</div>
					    	<div class="elem-item">
						    	<ion-label>
									<h2>{{ serv.nombre }}</h2>
									<h3>{{ serv.prestador.empresa }}</h3>
								</ion-label>
						    </div>
					    </ion-item>
					</div>
				</ion-list>
			</div>
		</div>
	</ion-content>
</ion-page>

<ion-page>
  <ion-header>
    <ion-toolbar color="primary">
      <ion-buttons slot="start">
        <ion-menu-button></ion-menu-button>
      </ion-buttons>
      <ion-title>Login</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content :fullscreen="true">
    <div class="content">
      <div class="login-logo mas">
        <img class="logo" src="/assets/logo/logo.png" alt="Ionic logo">
      </div>

      <form v-on:submit="Login">
        <ion-list class="form-login">
          <ion-row responsive-lg>
            <ion-col>
              <ion-item class="ion-no-padding">
                <ion-input v-model="username" name="username" type="text" autocapitalize="off"
                  placeholder="Correo electrónico" required></ion-input>

              </ion-item>
            </ion-col>
          </ion-row>
          
          <ion-row responsive-lg>
            <ion-col>
              <ion-item class="ion-no-padding">
                <ion-input v-model="password" name="password" placeholder="Contraseña" type="password" required>
                </ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-button type="submit" expand="block">Iniciar sesión</ion-button>
            </ion-col>
          </ion-row>
        </ion-list>
      </form>
    </div>
    <ExploreContainer name="Tab 1 page" />
  </ion-content>
</ion-page>